/* eslint-disable jsx-a11y/anchor-is-valid */
import type { FC } from 'react';
import { useState } from 'react';

import { FiMenu, FiX } from 'react-icons/fi';

import NextLink from 'next/link';

import { IHeader } from '@pra-vendas-themes/interfaces/IHeader';
import { ICategory } from '@pra-vendas-themes/interfaces/ICategory';
import Menu from '@pra-vendas-themes/default/components/Header/components/Menu';
import { CategoriesMobile } from '@pra-vendas-themes/default/components/Header/components/CategoriesMobile';
import { Categories } from '@pra-vendas-themes/default/components/Header/components/Categories';

import { SearchBar } from './components/SearchBar';

import {
  Wrapper,
  WrapperSearchBar,
  Content,
  Logo,
  TopBar,
  WrapperLogo,
  NavBar,
  Overlay,
  NavBarMobile,
} from './styles';

// const Menu = dynamic(() => import('./components/Menu'), { ssr: false });

const Header: FC<IHeader> = ({
  logo,

  isLogged,
  isUserMenuVisible,

  onClickUser,
  onClickFavorite,
  onClickBag,

  searchPlaceholder,
  onSearchClick,

  authMenuLinks,
  logout,

  categories,
  onClickCategory,

  itemsQuantityBag,
}) => {
  const [searchIsOpen, setSearchIsOpen] = useState(false);
  const [menuIsOpen, setMenuIsOpen] = useState(false);

  const handleClickUser = (): void => {
    if (onClickUser) onClickUser();
  };

  const handleClickFavorite = (): void => {
    if (onClickFavorite) onClickFavorite();
  };

  const handleClickBag = (): void => {
    if (onClickBag) onClickBag();
  };

  const handleSearchClick = (searchValue: string): void => {
    if (onSearchClick) onSearchClick(searchValue);
    setSearchIsOpen(false);
  };

  const handleClickSearch = (): void => {
    setSearchIsOpen((prev) => !prev);
  };

  const handleClickCategory = (category: ICategory): void => {
    if (menuIsOpen) setMenuIsOpen(false);
    if (onClickCategory) onClickCategory(category);
  };

  return (
    <>
      <Wrapper>
        <Content>
          <TopBar>
            <WrapperLogo>
              <FiMenu onClick={() => setMenuIsOpen((prev) => !prev)} />
              <NextLink href="/">
                {logo ? <Logo src={logo} /> : <span>LOGO</span>}
              </NextLink>
            </WrapperLogo>

            <SearchBar
              placeholder={searchPlaceholder}
              handleSearchClick={handleSearchClick}
            />

            <Menu
              logout={logout}
              isLogged={isLogged}
              isUserMenuVisible={isUserMenuVisible}
              handleClickSearch={handleClickSearch}
              handleClickUser={handleClickUser}
              handleClickFavorite={handleClickFavorite}
              handleClickBag={handleClickBag}
              authMenuLinks={authMenuLinks}
              itemsQuantityBag={itemsQuantityBag}
            />
          </TopBar>
          <WrapperSearchBar
            aria-hidden={!searchIsOpen}
            className={searchIsOpen ? 'isVisible' : ''}
          >
            <SearchBar
              placeholder={searchPlaceholder}
              handleSearchClick={handleSearchClick}
            />
          </WrapperSearchBar>
          <NavBar aria-hidden>
            <Categories categories={categories} />
          </NavBar>
        </Content>
      </Wrapper>
      <Overlay
        onClick={() => setMenuIsOpen(false)}
        className={menuIsOpen ? 'isOpen' : ''}
      />
      <NavBarMobile className={menuIsOpen ? 'isOpen' : ''}>
        <header>
          <h1>MENU</h1>
          <button type="button" onClick={() => setMenuIsOpen(false)}>
            <FiX />
          </button>
        </header>

        <CategoriesMobile
          onClickCategory={handleClickCategory}
          categories={categories}
        />
      </NavBarMobile>
    </>
  );
};

export default Header;
