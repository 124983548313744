import styled from 'styled-components';

export const Wrapper = styled.header`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(p) => p.theme.colors.primary};
  border-bottom: 1px solid ${(p) => p.theme.colors.border};

  flex-direction: column;

  position: sticky;
  left: 0;
  right: 0;
  top: 0;
  z-index: 300;
`;

export const Content = styled.div`
  position: relative;
  overflow: unset;
  background-color: ${(p) => p.theme.header.background};
  width: 100%;

  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-content: center;
`;

export const TopBar = styled.div`
  z-index: 2;
  width: ${(p) => p.theme.maxWidth};
  max-width: 100%;
  padding: ${(p) => `12px ${p.theme.spacing.xLarge}`};
  background-color: ${(p) => p.theme.header.background};

  display: flex;
  align-items: center;
  justify-content: space-between;

  gap: ${(p) => p.theme.spacing.xLarge};

  @media (max-width: 768px) {
    padding: ${(p) => p.theme.spacing.medium} ${(p) => p.theme.spacing.medium};

    & > form {
      display: none;
    }
  }
`;

export const WrapperLogo = styled.div`
  font-size: ${(p) => p.theme.font.large};
  display: flex;
  align-items: center;
  gap: ${(p) => p.theme.spacing.medium};
  cursor: pointer;
  max-height: 100%;

  transition: opacity 0.4s;

  &:hover {
    opacity: 0.6;
  }

  & svg {
    cursor: pointer;
    stroke-width: 2;
  }

  & span {
    font-weight: bold;
    font-size: ${(p) => p.theme.font.xxLarge};
  }

  > a {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @media (min-width: 768px) {
    & svg {
      display: none;
    }
  }
`;

export const NavBar = styled.div`
  width: ${(p) => p.theme.maxWidth};
  max-width: 100%;
  padding: 0 ${(p) => p.theme.spacing.xLarge};

  @media (max-width: 768px) {
    display: none;
  }
`;

export const Logo = styled.img`
  cursor: pointer;
  width: 120px;
  max-height: 80px;

  object-position: left;
  object-fit: contain;

  transition: opacity 0.3s;
  will-change: opacity;

  &:hover {
    opacity: 0.85;
  }

  @media (max-width: 768px) {
    width: 90px;
    max-height: 56px;
  }
`;

export const WrapperSearchBar = styled.div`
  width: 100%;
  display: flex;
  align-content: center;
  justify-content: center;
  border-bottom: 1px solid ${(p) => p.theme.colors.border};
  background-color: ${(p) => p.theme.header.background};

  position: absolute;
  top: 100%;

  transition: transform 0.3s;

  transform: translateY(-100%);

  &.isVisible {
    transform: translateY(0);
  }

  @media (min-width: 769px) {
    display: none;
  }

  padding: 0 ${(p) => p.theme.spacing.medium} ${(p) => p.theme.spacing.medium};

  & > form {
    max-width: 100%;
  }
`;

export const Overlay = styled.div`
  position: absolute;
  background-color: black;

  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  z-index: 300;

  visibility: hidden;
  opacity: 0;

  transition: visibility 0.3s, opacity 0.3s;

  &.isOpen {
    opacity: 0.25;
    visibility: visible;
  }
`;

export const NavBarMobile = styled.div`
  position: absolute;
  background-color: white;

  top: 0;
  left: 0;
  bottom: 0;
  width: 300px;

  z-index: 300;

  border-right: 1px solid ${(p) => p.theme.colors.border};

  visibility: hidden;
  transform: translateX(-100%);

  transition: transform 0.3s, visibility 0.3s;

  &.isOpen {
    transform: translateX(0);
    visibility: visible;
  }

  > header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: ${(p) => p.theme.colors.background};
    padding: ${(p) =>
      `${p.theme.spacing.large} ${p.theme.spacing.large} ${p.theme.spacing.medium}`};

    border-bottom: 1px solid ${(p) => p.theme.colors.border};

    position: sticky;
    top: 0;

    @media (max-width: 768px) {
      font-size: ${(p) => p.theme.font.large};

      padding: ${(p) => p.theme.spacing.medium};
    }

    > h1 {
      color: ${(p) => p.theme.colors.textPrimary};
      font-size: ${(p) => p.theme.font.xLarge};
      text-transform: uppercase;

      @media (max-width: 768px) {
        font-size: ${(p) => p.theme.font.large};
      }
    }

    > button {
      cursor: pointer;
      color: ${(p) => p.theme.colors.textPrimary};
      background-color: transparent;
      border: none;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: ${(p) => p.theme.font.xLarge};

      & svg {
        stroke-width: 3;
      }
    }
  }
`;
