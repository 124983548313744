import styled, { css } from 'styled-components';

export const SubCategories = styled.div`
  width: ${(p) => p.theme.maxWidth};
  max-width: 100%;
  padding: 0 ${(p) => p.theme.spacing.xLarge};

  display: flex;
  align-items: flex-start;

  color: ${(p) => p.theme.colors.textSecondary};

  flex-direction: column;
`;

export const ContentDropdown = styled.div`
  width: ${(p) => p.theme.maxWidth};
  max-width: 100%;
  padding: 0 ${(p) => p.theme.spacing.xLarge};

  display: flex;
  align-items: stretch;

  color: ${(p) => p.theme.colors.textSecondary};
`;

type TWrapperList = {
  bordered?: boolean;
};

export const WrapperList = styled.div<TWrapperList>`
  display: flex;
  flex-direction: column;
  gap: ${(p) => p.theme.spacing.medium};
  padding: 0 ${(p) => p.theme.spacing.xLarge} 0 ${(p) => p.theme.spacing.xLarge};

  max-height: 75vh;
  overflow-x: auto;
  height: auto;

  &:first-child {
    padding-left: 0;
  }

  &:last-child {
    border-right: none;
  }
`;

export const ItemTitle = styled.h2`
  cursor: pointer;
  color: ${(p) => p.theme.colors.textPrimary};
  text-decoration: underline;
  font-size: ${(p) => p.theme.font.large};
  text-transform: uppercase;
`;

export const ItemList = styled.div`
  cursor: pointer;

  & a:hover {
    text-decoration: underline;
  }

  display: inline-flex;
  align-items: center;
  width: 100%;

  gap: ${(p) => p.theme.spacing.small};

  & svg {
    font-size: 15px;
    line-height: 1em;
    stroke-width: 2;
    margin: 0;
  }

  & a {
    text-transform: uppercase;
    cursor: pointer;
    font-size: ${(p) => p.theme.font.xSmall};
    color: ${(p) => p.theme.colors.textSecondary};
  }

  & a.isActive {
    text-decoration: underline;
  }
`;

export const AllCategories = styled.div`
  width: ${(p) => p.theme.maxWidth};
  max-width: 100%;
  padding: 0 ${(p) => p.theme.spacing.xLarge};

  display: flex;
  align-items: flex-start;

  color: ${(p) => p.theme.colors.textSecondary};
`;

export const SubCategoriesList = styled.div`
  max-height: 200px;
  overflow: auto;

  display: flex;
  flex-direction: column;
  align-items: flex-start;

  padding: 0 ${(p) => p.theme.spacing.giant} 0 0;
`;

type TWrapperCategories = {
  fullWidth: boolean;
};

export const WrapperCategories = styled.div<TWrapperCategories>`
  display: flex;
  gap: ${(p) => p.theme.spacing.xLarge};
  color: transparent;

  ${(p) =>
    p.fullWidth &&
    css`
      flex: 1;
      justify-content: space-between;
    `}

  &.isVisible {
    color: inherit;
  }
`;

export const WrapperDropdown = styled.div`
  cursor: default;
  background: ${(p) => p.theme.colors.background};
  border-bottom: 1px solid ${(p) => p.theme.colors.border};
  position: absolute;
  width: 100vw;
  top: 100%;
  left: 50%;
  transform: translateX(-50%) translateY(-100%);
  padding: ${(p) => p.theme.spacing.small} 0 ${(p) => p.theme.spacing.medium};

  transition: transform 0.3s ease-in;
  will-change: transform;

  z-index: -1;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  &.isHide {
    transform: translateX(-50%) translateY(-100%) !important;
    pointer-events: none;
  }
`;

export const Category = styled.div`
  white-space: nowrap;
  cursor: pointer;
  padding: ${(p) => p.theme.spacing.small} 0;
  line-height: ${(p) => p.theme.spacing.medium};

  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${(p) => p.theme.spacing.nano};

  &:hover {
    text-decoration: underline;

    ${WrapperDropdown} {
      transform: translateX(-50%) translateY(0%);
    }
  }

  &.Title {
    color: ${(p) => p.theme.colors.primary};
    font-weight: 500;
  }

  & svg {
    font-size: 15px;
    line-height: 1em;
    stroke-width: 2;
    margin-bottom: 1px;
  }

  & a {
    text-transform: uppercase;
    cursor: pointer;
    font-size: ${(p) => p.theme.font.xSmall};
    color: ${(p) => p.theme.colors.textSecondary};

    display: flex;
    align-items: center;

    gap: ${(p) => p.theme.spacing.nano};

    & svg {
      margin-bottom: 0;
    }
  }
`;

export const Wrapper = styled.div`
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: ${(p) => p.theme.font.xSmall};
  color: ${(p) => p.theme.colors.textSecondary};
  z-index: 2;
  gap: ${(p) => p.theme.spacing.xLarge};
`;

export const SubCategory = styled.div`
  padding: ${(p) => p.theme.spacing.small} 0;

  & a:hover {
    text-decoration: underline;
  }

  display: flex;
  align-items: center;
  width: 100%;

  gap: ${(p) => p.theme.spacing.small};

  & svg {
    font-size: 15px;
    line-height: 1em;
    stroke-width: 0;
    margin: 0;
  }

  & a {
    text-transform: uppercase;
    cursor: pointer;
    font-size: ${(p) => p.theme.font.xSmall};
    color: ${(p) => p.theme.colors.textSecondary};
  }
`;

export const WrapperRight = styled.div`
  & svg {
    font-size: 12px;
    line-height: 1em;
    stroke-width: 0;
    margin-bottom: 1px;
  }
`;
