/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { FC } from 'react';
import { useEffect, useRef, useState } from 'react';

import { FiChevronDown, FiChevronRight, FiMenu } from 'react-icons/fi';

import NextLink from 'next/link';

import useFontFaceObserver from 'use-font-face-observer';
import { ICategory } from '@pra-vendas-themes/interfaces/ICategory';

import {
  Wrapper,
  Category,
  WrapperDropdown,
  WrapperCategories,
  SubCategories,
  SubCategory,
  ContentDropdown,
  WrapperList,
  ItemList,
} from './styles';

type TCategoriesComponent = {
  categories?: ICategory[];
  defaultFilteredCategories?: ICategory[];
};

export const Categories: FC<TCategoriesComponent> = ({ categories }) => {
  const isFontListLoaded = useFontFaceObserver([{ family: `Montserrat` }]);

  const categoriesRef = useRef<HTMLDivElement>(null);

  const [categoriesFiltered, setCategoriesFiltered] = useState(categories);
  const [subCategoriesVisible, setSubCategoriesVisible] = useState('');
  const [categoriesVisible, setCategoriesVisibile] = useState(false);
  const [hideDropdown, setHideDropdown] = useState(false);

  useEffect(() => {
    if (isFontListLoaded) {
      if (categoriesRef.current && categoriesRef.current.offsetWidth > 1040) {
        setCategoriesFiltered((prev) => prev?.slice(0, prev?.length - 1));
      } else {
        setCategoriesVisibile(true);
      }
    }
  }, [categoriesFiltered, isFontListLoaded]);

  const handleClickCategory = (): void => {
    setHideDropdown(true);

    setTimeout(() => {
      setHideDropdown(false);
    }, 1000);
  };

  return (
    <Wrapper>
      <WrapperCategories
        fullWidth={categoriesFiltered?.length !== categories?.length}
        ref={categoriesRef}
        className={categoriesVisible ? 'isVisible' : ''}
      >
        {categoriesFiltered?.length !== categories?.length && (
          <Category className="Title">
            <FiMenu />
            Todas as Categorias
            <WrapperDropdown
              className={hideDropdown ? 'isHide' : ''}
              onMouseLeave={() => setSubCategoriesVisible('')}
            >
              <ContentDropdown>
                <WrapperList bordered={!!subCategoriesVisible}>
                  {categories?.map((category) => (
                    <ItemList key={category._id}>
                      <NextLink
                        href={`/categorias/${category.tag}`}
                        onClick={handleClickCategory}
                        className={
                          subCategoriesVisible === category._id
                            ? 'isActive'
                            : ''
                        }
                        onMouseEnter={() => {
                          if (category.subcategories?.length) {
                            setSubCategoriesVisible(category?._id);
                          } else {
                            setSubCategoriesVisible('');
                          }
                        }}
                      >
                        {category.name}
                      </NextLink>

                      {!!category?.subcategories?.length && <FiChevronRight />}
                    </ItemList>
                  ))}
                </WrapperList>
                <WrapperList>
                  {categories
                    ?.find((category) => category._id === subCategoriesVisible)
                    ?.subcategories?.map?.((subcategory) => (
                      <ItemList key={subcategory._id}>
                        <NextLink
                          href={`/categorias/${subcategory.tag}`}
                          onClick={handleClickCategory}
                        >
                          {subcategory.name}
                        </NextLink>
                      </ItemList>
                    ))}
                </WrapperList>
              </ContentDropdown>
            </WrapperDropdown>
          </Category>
        )}

        {categoriesFiltered?.map((category) => (
          <Category key={category._id}>
            <NextLink
              href={`/categorias/${category.tag}`}
              onClick={handleClickCategory}
            >
              {category.name}
              {!!category.subcategories?.length && <FiChevronDown />}
            </NextLink>
            {!!category.subcategories?.length && (
              <WrapperDropdown className={hideDropdown ? 'isHide' : ''}>
                <SubCategories>
                  {category?.subcategories?.map((subcategory) => (
                    <SubCategory key={subcategory._id}>
                      <NextLink
                        href={`/categorias/${subcategory.tag}`}
                        onClick={handleClickCategory}
                      >
                        {subcategory.name}
                      </NextLink>
                    </SubCategory>
                  ))}
                </SubCategories>
              </WrapperDropdown>
            )}
          </Category>
        ))}
      </WrapperCategories>
    </Wrapper>
  );
};
