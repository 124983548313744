import styled from 'styled-components';

export const SubCategories = styled.div`
  padding: 0 0 0 ${(p) => p.theme.spacing.medium};
  gap: 16px;

  display: flex;
  align-items: flex-start;

  color: ${(p) => p.theme.colors.textSecondary};

  flex-direction: column;

  width: 100%;

  overflow: hidden;

  transition: all 0.3s;

  padding: ${(p) => p.theme.spacing.medium} 0 0 ${(p) => p.theme.spacing.medium};
`;

export const SubCategoriesList = styled.div`
  max-height: 400px;
  overflow: auto;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;

  padding: 0 ${(p) => p.theme.spacing.xxxLarge} 0 0;
`;

export const WrapperCategories = styled.div`
  display: flex;
  gap: ${(p) => p.theme.spacing.small};
  color: transparent;
  width: 100%;

  flex-direction: column;
  align-items: flex-start;

  &.isVisible {
    color: inherit;
  }
`;

interface CategoryProps {
  isOpen: boolean;
}

export const Category = styled.div<CategoryProps>`
  cursor: pointer;
  padding: ${(p) => p.theme.spacing.small} 0;
  line-height: ${(p) => p.theme.spacing.medium};

  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;

  width: 100%;

  &.Title {
    color: ${(p) => p.theme.colors.primary};
    font-weight: 500;
    margin-right: auto;
  }

  & > svg {
    font-size: 12px;
    line-height: 1em;
    stroke-width: 3;
    margin-bottom: 1px;
    color: ${(p) => p.theme.colors.textSecondary};

    transition: transform 0.3s;
    will-change: transform;
    transform: ${(p) => (p.isOpen ? 'rotate(180deg)' : 'rotate(0deg)')};
  }

  & button {
    text-transform: uppercase;
    cursor: pointer;
    font-size: ${(p) => p.theme.font.xSmall};
    color: ${(p) => p.theme.colors.textSecondary};
  }

  & > div {
    width: 100%;
  }
`;

export const Wrapper = styled.div`
  text-transform: uppercase;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: ${(p) => p.theme.font.xSmall};
  color: ${(p) => p.theme.colors.textSecondary};
  z-index: 2;
  padding: 10px;
`;

export const SubCategory = styled.div`
  display: flex;
  align-items: center;
  width: 100%;

  & svg {
    font-size: 11px;
    line-height: 1em;
    stroke-width: 3;
    margin-bottom: 1px;
    color: ${(p) => p.theme.colors.textSecondary};
  }

  & button {
    text-transform: uppercase;
    cursor: pointer;
    font-size: ${(p) => p.theme.font.xSmall};
    color: ${(p) => p.theme.colors.textSecondary};
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: flex-start;
    gap: ${(p) => p.theme.spacing.small};
  }
`;
