import { ChangeEvent, FC, FormEvent, useState } from 'react';
import { IoSearchOutline } from 'react-icons/io5';

import Button from '@pra-vendas-themes/default/components/Button';
import Input from '@pra-vendas-themes/default/components/Form/Input';

import { Wrapper } from './styles';

type TSearchBar = {
  placeholder?: string;
  handleSearchClick?: (searchValue: string) => void;
};

export const SearchBar: FC<TSearchBar> = ({
  placeholder,
  handleSearchClick,
}) => {
  const [searchValue, setSearchValue] = useState('');

  function handleSetSearchValue(e: ChangeEvent<HTMLInputElement>): void {
    setSearchValue(e.target.value);
  }

  function handleSubmit(e: FormEvent<HTMLFormElement>): void {
    e.preventDefault();

    setSearchValue('');
    if (handleSearchClick) handleSearchClick(searchValue);
  }

  return (
    <Wrapper onSubmit={handleSubmit}>
      <Input
        name="search"
        placeholder={placeholder}
        color="secondary"
        value={searchValue}
        onChange={handleSetSearchValue}
      />
      <Button size="xSmall" type="submit">
        <IoSearchOutline />
      </Button>
    </Wrapper>
  );
};
