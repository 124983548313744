import { FC } from 'react';
import { useState } from 'react';

import { FiChevronUp, FiCornerDownRight } from 'react-icons/fi';

import { ICategory } from '@pra-vendas-themes/interfaces/ICategory';
import { Collapse } from '@pra-vendas-themes/default/components/Collapse';

import {
  Wrapper,
  Category,
  WrapperCategories,
  SubCategories,
  SubCategory,
} from './styles';

type TCategoriesComponent = {
  categories?: ICategory[];
  onClickCategory: (category: ICategory) => void;
};

export const CategoriesMobile: FC<TCategoriesComponent> = ({
  categories,
  onClickCategory,
}) => {
  const [subCategoriesVisible, setSubCategoriesVisible] = useState('');

  return (
    <Wrapper>
      <WrapperCategories>
        {categories?.length > 0 &&
          categories.map((category) => (
            <Category
              key={category._id}
              isOpen={subCategoriesVisible === category._id}
            >
              <button type="button" onClick={() => onClickCategory(category)}>
                {category.name}{' '}
              </button>

              {!!category?.subcategories?.length && (
                <FiChevronUp
                  onClick={() =>
                    setSubCategoriesVisible(
                      subCategoriesVisible === category._id ? '' : category._id
                    )
                  }
                />
              )}
              {!!category.subcategories?.length && (
                <Collapse isOpen={subCategoriesVisible === category._id}>
                  <SubCategories>
                    {category?.subcategories?.map((subcategory) => (
                      <SubCategory key={subcategory._id}>
                        <button
                          type="button"
                          onClick={() => onClickCategory(subcategory)}
                        >
                          <FiCornerDownRight />
                          {subcategory.name}
                        </button>
                      </SubCategory>
                    ))}
                  </SubCategories>
                </Collapse>
              )}
            </Category>
          ))}
      </WrapperCategories>
    </Wrapper>
  );
};
