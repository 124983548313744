import type { FC } from 'react';
import { FiHeart, FiShoppingBag, FiUser } from 'react-icons/fi';
import { IoSearchOutline } from 'react-icons/io5';

import dynamic from 'next/dynamic';

import { IAuthMenuLinks } from '@pra-vendas-themes/interfaces/IAuthMenuLinks';

import {
  Wrapper,
  Tooltip,
  MenuItem,
  MenuItemContent,
  BadgeCount,
} from './styles';

const DropDownUser = dynamic(() => import('./DropDownUser'), { ssr: false });

type TMenuComponent = {
  isLogged?: boolean;
  isUserMenuVisible?: boolean;
  handleClickUser?: () => void;
  handleClickFavorite?: () => void;
  handleClickBag?: () => void;
  handleClickSearch?: () => void;
  authMenuLinks?: IAuthMenuLinks;
  logout?: () => void;
  itemsQuantityBag?: number;
};

const Menu: FC<TMenuComponent> = ({
  isLogged,
  isUserMenuVisible,
  handleClickUser,
  handleClickFavorite,
  handleClickBag,
  handleClickSearch,
  authMenuLinks,
  logout,
  itemsQuantityBag,
}) => {
  return (
    <Wrapper>
      <MenuItem className="Search" onClick={handleClickSearch}>
        <MenuItemContent data-title="Pesquisar">
          <IoSearchOutline />
        </MenuItemContent>
      </MenuItem>
      <MenuItem className="user" onClick={handleClickUser}>
        <Tooltip
          className={
            isUserMenuVisible && isLogged ? 'menu--open tooltip--off' : ''
          }
          data-title="Minha conta"
        >
          <MenuItemContent>
            <FiUser />
          </MenuItemContent>
        </Tooltip>
        <DropDownUser
          isLogged={isLogged}
          isUserMenuVisible={isUserMenuVisible}
          authMenuLinks={authMenuLinks}
          logout={logout}
        />
      </MenuItem>

      <MenuItem onClick={handleClickFavorite}>
        <Tooltip data-title="Lista de desejos">
          <MenuItemContent>
            <FiHeart />
          </MenuItemContent>
        </Tooltip>
      </MenuItem>
      <MenuItem onClick={handleClickBag}>
        <Tooltip data-title="Sacola">
          <MenuItemContent suppressHydrationWarning>
            {(itemsQuantityBag || 0) > 0 && (
              <BadgeCount>{itemsQuantityBag}</BadgeCount>
            )}
            <FiShoppingBag />
          </MenuItemContent>
        </Tooltip>
      </MenuItem>
    </Wrapper>
  );
};

export default Menu;
