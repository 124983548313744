import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  z-index: 1;
  gap: ${(p) => p.theme.spacing.medium};
`;

export const BadgeCount = styled.span`
  position: absolute;
  z-index: 1;
  bottom: -6px;
  left: -6px;
  background-color: ${(p) => p.theme.colors.primary};
  color: ${(p) => p.theme.colors.textLight};
  font-size: 9px;
  width: 15px;
  height: 15px;
  letter-spacing: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  border: 1px solid white;
  border-radius: 100%;

  transition: transform 0.3s;
  will-change: transform;

  &:hover {
    transform: scale(1.25);
  }
`;

export const MenuItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  &.user {
    z-index: 2;
  }

  @media (min-width: 768px) {
    &.Search {
      display: none;
    }
  }
`;

export const MenuItemContent = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: none;
  background-color: transparent;
  color: inherit;
  font-size: ${(p) => p.theme.font.xLarge};

  position: relative;

  transition: opacity 0.3s;
  will-change: opacity;

  z-index: 1;

  & svg {
    transition: color 0.3s, fill 0.3s, opacity 0.3s;
    will-change: color, fill, opacity;
  }

  &:not(.menu--open):hover {
    opacity: 0.5;
  }

  &.menu--open {
    & svg {
      color: ${(p) => p.theme.colors.background};
      fill: ${(p) => p.theme.colors.background};
    }
  }

  &.menu--open ~ {
    color: red;
  }

  @media (max-width: 768px) {
    font-size: ${(p) => p.theme.font.large};
  }
`;

export const Tooltip = styled.div``;

export const DropDown = styled.div`
  position: absolute;
  top: calc(100% + 15px);
  right: -16px;
  background-color: ${(p) => p.theme.colors.background};
  z-index: 1;
  min-width: 170px;

  ::before {
    content: '';
    position: absolute;
    bottom: 100%;
    right: 16px;
    background-color: transparent;
    border-width: calc(${(p) => p.theme.font.xLarge} / 2);
    border-style: solid;
    border-color: transparent transparent ${(p) => p.theme.colors.background};
  }

  opacity: 0;
  visibility: hidden;

  transition: opacity 0.3s, visibility 0.3s, transform 0.3s;
  will-change: opacity, visibility, transform;
  transform: scale(0.9);

  &.isVisible {
    opacity: 1;
    visibility: visible;
    transform: scale(1);
  }
`;

export const Item = styled.div`
  text-decoration: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  white-space: nowrap;
  padding: ${(p) => p.theme.spacing.small} ${(p) => p.theme.spacing.medium};
  line-height: ${(p) => p.theme.spacing.large};
  gap: ${(p) => p.theme.spacing.small};
  font-size: ${(p) => p.theme.font.small};
  letter-spacing: 1px;
  font-weight: 400;

  color: inherit;

  transition: background-color 0.3s;
  will-change: background-color;

  &:hover {
    background-color: ${(p) => p.theme.colors.backgroundHover};
  }

  & > svg {
    font-size: ${(p) => p.theme.font.medium};
    margin-right: ${(p) => p.theme.spacing.small};
  }
`;

export const Overlay = styled.div`
  background-color: #000;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  opacity: 0;
  visibility: hidden;

  transition: opacity 0.3s, visibility 0.3s;
  will-change: opacity, visibility;

  &.isVisible {
    opacity: 0.45;
    visibility: visible;
  }
`;
