import styled from 'styled-components';

export const Wrapper = styled.form`
  border-radius: ${(p) => p.theme.borderRadius};
  flex: 1;
  display: flex;
  max-width: 450px;

  & input {
    border-right: none !important;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  & button {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    width: 40px;
  }
`;
